<template>
  <v-row justify="center" v-if="shower === true">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="700px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("keydetail.details") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-information</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:525px">
          <!-- <div class="text-center">
           <v-progress-circular
              v-if="createprogress === true"
              indeterminate
              :color="color.theme"
              size="70"             
            >
           <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-progress-circular>
          </div> -->
          <v-tabs :color="color.theme">
            <v-tab @click="tab_general = true, tab_activity = false, tab_smartbox = false">{{ $t("keydetail.general") }}</v-tab>
            <v-tab @click="tab_general = false, tab_activity = true, tab_smartbox = false">{{ $t("keydetail.activity") }}</v-tab>
            <v-tab @click="tab_general = false, tab_activity = false, tab_smartbox = true">{{"smart box"}}</v-tab>
          </v-tabs>
          <div v-if="tab_general">
            <!-- <v-layout xs12 lg12 row wrap>
              <v-flex xs12 lg12>
                <v-subheader>
                  {{ $t("keydetail.general") }}
                  <v-divider class="mx-4"></v-divider>
                </v-subheader>
              </v-flex>
            </v-layout> -->
            <v-layout xs12 lg12 row wrap>
              <v-flex xs12 lg12 class="pl-4 pt-4">
                <v-icon
                  x-large
                  :color="filedata.file_icon[1]"
                  style="font-weight: 100"
                  >{{ filedata.file_icon[0] }}</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 pt-4"
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filename") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4 pt-4"
                ><p>{{ dataObj.name }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filesize") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>
                  {{ calculatesize(dataObj.size) }}
                  <v-progress-circular
                    v-if="load_size === true"
                    indeterminate
                    :size="15"
                    :width="2"
                    class="ma-0 mt-n1 ml-2"
                  ></v-progress-circular></p
              ></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filetype") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ dataObj.type || dataObj.data_type }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.filedate") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ calculatedate(dataObj.cre_dtm) }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.owner") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>
                  {{
                    $t("default") === "th" ? dataObj.name_th : dataObj.name_eng
                  }}
                </p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.path") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ dataObj.full_path }}</p></v-flex
              >
            </v-layout>
            <v-layout
              xs12
              lg12
              row
              wrap
              v-if="dataAccountActive.type === 'Business'"
            >
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.permission") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4"
                ><p>{{ permission(filedata.file_permission_2) }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">
                  {{ $t("keydetail.expiration") }} :
                </p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4 "
                ><p>{{ calculatedate(dataObj.expiration_time) }}</p></v-flex
              >
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg2 class="pl-4 "
                ><p class="font-weight-bold">Hashtag</p></v-flex
              >
              <v-flex xs6 lg10 class="pl-4 ">
                <v-btn
                  x-small
                  outlined
                  class="elevation-0 text-right"
                  color="error"
                  :disabled="
                    createprogress ||
                      filedata.editing_status !== 'N' ||
                      filedata.file_permission_2 === '01' ||
                        filedata.file_permission_2 === '02'
                  "
                  @click="opendialoghashtag()"
                  ><v-icon
                    class="ma-1"
                    dark
                    small
                    v-if="filedata.hashtag !== ''"
                    >edit</v-icon
                  >{{ $t("edithashtag.edit") }}</v-btn
                ></v-flex
              >
            </v-layout>
            <v-layout>
              <v-flex>
                <div v-if="dataObj.hashtag == ''">-</div>
                <div v-else>
                  <v-chip
                    small
                    class="ma-1"
                    v-for="item in dataObj.hashtag"
                    :key="item"
                    >#{{ item }}</v-chip
                  >
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="tab_activity">
            <v-data-table
                        :headers="header"
                        :items="history_activity"
                        class="elevation-0"
                        :no-data-text="$t('keydetail.no_data_activity')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <template v-slot:[`header.name`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.dtm`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.activity`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr class="pointer" style="cursor:pointer;">
                            <td v-if="$t('default') === 'th'" class="text-center" width="33%">{{ props.item.name_th === null ? "ไม่ระบุชื่อ" : props.item.name_th}}</td>
                            <td v-else class="text-center" width="33%">{{props.item.name_eng === null ? "Anonymous" : props.item.name_eng}}</td>
                            <td class="text-center" width="33%">{{ formatdatetime(props.item.process_dtm)}}</td>
                            <td class="text-center" width="33%">{{ props.item.process_name }}</td>
                          </tr>
                        </template>
                      </v-data-table>
          </div>
          <div v-if="tab_smartbox">
            <div class="d-flex flex-row justify-center pa-3" v-if="isloadingAspect" >
              <v-progress-circular 
                size="32"
                indeterminate
                color="primary"
              /> 
            </div>
            <!-- มีข้อมูล aspect -->
            <div v-if="smart_box === true && !isloadingAspect">
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg3 class="pl-4 pt-4"
                ><p class="font-weight-bold" v-if="$t('default') === 'th'">
                  {{'ชื่อเทมเพลส'}} :
                </p>
                <p class="font-weight-bold" v-else>
                  {{'Template Name'}} :
                </p>
                </v-flex
              >
              <v-flex xs6 lg9 class="pl-0 pt-4"
              >
              {{data_smartbox.templateName}}
              </v-flex>
            </v-layout>
            <div 
            v-for="(item, i) in data_smartbox.dataAspect"
            :key="i"
            >
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg3 class="pl-4"
                ><p class="font-weight-bold">
                  {{item.aspect_label}} :
                </p></v-flex
              >
              <v-flex xs6 lg8 class="pl-0"
              >
              {{ item.aspect_type === 'DATE' ? calculatedate_smartbox(item.value) : item.value}}
              <v-btn text icon @click="fn_editAspect(item)" >
                  <v-icon small>
                    {{ item.aspect_type === 'DATE' ? 'mdi-calendar' : 'mdi-pencil' }}
                  </v-icon>
              </v-btn>
              </v-flex>
            </v-layout>
            </div>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg3 class="pl-4"
                ><p class="font-weight-bold">
                  เวอร์ชั่น :
                </p></v-flex
              >
              <v-flex xs6 lg9 class="pl-0"
              >
              {{all_tags_version.find((item) => item.file_id === filedata.file_id ).version }}
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs4 lg3 class="pl-4"
                ><p class="font-weight-bold">
                  จำกัดเวอร์ชั่น :
                </p></v-flex
              >
              <v-flex xs6 lg9 class="pl-0" v-if="!isOpenTextFiled_version"
              >
              {{ maxVersion }}
              <v-btn text icon @click="isOpenTextFiled_version = true" >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
              </v-btn>
              </v-flex>
              <v-flex  xs6 lg2 md1 class="pl-0" v-if="isOpenTextFiled_version">
               <v-text-field hide-details type="number"
               dense v-model="maxVersion" outlined  >
               </v-text-field>
              </v-flex>
              <v-flex row xs6 lg2 md1 class="pl-0 ml-2" v-if="isOpenTextFiled_version"
              >
              <v-btn text icon @click="fn_closeMaxVersionEdit" >
                  <v-icon>
                    mdi-close
                  </v-icon>
              </v-btn>
              </v-flex> 
              <!-- <v-flex row xs6 lg1 md1 class="pl-0" v-if="!isOpenTextFiled_version"
              >
              <v-btn text icon @click="isOpenTextFiled_version = true" >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
              </v-btn>
              </v-flex>
              <v-flex  xs6 lg2 md1 class="pl-0" v-if="isOpenTextFiled_version">
               <v-text-field hide-details type="number"
               dense v-model="maxVersion" outlined >
               </v-text-field>
              </v-flex>
              <v-flex row xs6 lg2 md1 class="pl-0 ml-2" v-if="isOpenTextFiled_version"
              >
              <v-btn text icon @click="(isOpenTextFiled_version = false), (isChangeMaxVersion = false)" >
                  <v-icon>
                    mdi-close
                  </v-icon>
              </v-btn>
              </v-flex> -->
            </v-layout>
            </div>
            <!-- ไม่มีข้อมูล aspect -->
            <div v-else-if="smart_box === false && !isloadingAspect">
               <v-layout xs12 lg12 row wrap style="justify-content: center">
              <v-flex xs4 lg2 class="pl-4 pt-4"
                ><p class="font-weight-bold" v-if="$t('default') === 'th'">
                  {{'ไม่พบข้อมูล'}}
                </p>
                <p class="font-weight-bold" v-else>
                  {{'No information found'}}
                </p>
                </v-flex>
            </v-layout>
            </div>
          </div>

          <!-- <v-list two-line subheader> -->
          <!--   <v-subheader>
              {{ $t("keydetail.general") }}
              <v-divider class="mx-4"></v-divider>
            </v-subheader>
            <v-list-item-icon style="padding-left: 4%">
              <v-icon x-large :color="filedata.file_icon[1]" style="font-weight: 100">{{ filedata.file_icon[0] }}</v-icon>
            </v-list-item-icon>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filename") }}</v-list-item-title>
                <v-list-item-subtitle :title="dataObj.name">{{ dataObj.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filesize") }}</v-list-item-title>
                <v-list-item-subtitle>{{ calculatesize(dataObj.size) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filetype") }}</v-list-item-title>
                <v-list-item-subtitle>{{ dataObj.type || dataObj.data_type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.filedate") }}</v-list-item-title>
                <v-list-item-subtitle>{{ calculatedate(dataObj.cre_dtm) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.owner") }}</v-list-item-title>
                <v-list-item-subtitle v-if="$t('default') === 'th'">{{ dataObj.name_th }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{ dataObj.name_eng }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.path") }}</v-list-item-title>
                <v-list-item-subtitle :title="dataObj.full_path">{{ dataObj.full_path }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="dataAccountActive.type === 'Business'">
              <v-list-item-content>
                <v-list-item-title>{{ $t("keydetail.permission") }}</v-list-item-title>
                <v-list-item-subtitle>{{ permission(filedata.file_permission) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
             <v-list-item-content>
                <v-list-item-title>วันหมดอายุไฟล์</v-list-item-title>
                <v-list-item-subtitle>{{calculatedate(dataObj.expiration_time)}}</v-list-item-subtitle>
             </v-list-item-content>
            </v-list-item>-->
          <!-- <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Hashtag</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
                <div v-if="dataObj.hashtag == ''">-</div>
                <div v-else><v-chip small class="ma-1" v-for="item in dataObj.hashtag" :key="item">#{{item}}</v-chip></div>
              </v-list-item-content>
                <v-list-item-action>
                 <v-btn x-small outlined class="elevation-0 text-right" color="error" :disabled="createprogress || filedata.editing_status !== 'N' || filedata.file_permission.edit !== 'True'" @click="opendialoghashtag()"><v-icon class="ma-1" dark small v-if="filedata.hashtag !== ''">edit</v-icon>{{ $t("edithashtag.edit")}}</v-btn>
                </v-list-item-action>
            </v-list-item>

          </v-list>  -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="tab_smartbox && smart_box && isChangeMaxVersion"
            color="success"
            :dark="color.darkTheme"
            @click="fn_restrict_version()"
            >เพิ่มเวอร์ชั่น</v-btn
          >
          <v-btn
            color="red"
            :dark="color.darkTheme"
            @click="status_reload === true ? (dataObj = {}, status_reload = false, $emit('closedreloadkeydetail'), maxVersion = null, isOpenTextFiled_version = false, isloadingAspect = false) : $emit('closedkeydetail'), (dataObj = {}), (maxVersion = null), (isOpenTextFiled_version = false), (isloadingAspect = false)"
            >{{ $t("keydetail.closeDialog") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="px-5 pt-4 pb-1">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-2">
              <span :style="headerPage">{{ $t("keydetail.details") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="status_reload === true ? (dataObj = {}, status_reload = false, $emit('closedreloadkeydetail')) : $emit('closedkeydetail'), (dataObj = {})"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-layout>
        <v-tabs :color="color.theme" class="pb-2" mobile-breakpoint>
            <v-tab @click="tab_general = true, tab_activity = false, tab_smartbox = false">{{ $t("keydetail.general") }}</v-tab>
            <v-tab @click="tab_general = false, tab_activity = true, tab_smartbox = false">{{ $t("keydetail.activity") }}</v-tab>
            <v-tab @click="tab_general = false, tab_activity = false, tab_smartbox = true">{{"smart box"}}</v-tab>
          </v-tabs>
        </v-layout>
        <v-card-text style="height:420px;" v-if="tab_general">
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filename") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ dataObj.name }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filesize") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>
                {{ calculatesize(dataObj.size) }}
                <v-progress-circular
                  v-if="load_size === true"
                  indeterminate
                  :size="15"
                  :width="2"
                  class="ma-0 mt-n1 ml-2"
                ></v-progress-circular></p
            ></v-flex>
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filetype") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ dataObj.type || dataObj.data_type }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.filedate") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ calculatedate(dataObj.cre_dtm) }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.owner") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>
                {{
                  $t("default") === "th" ? dataObj.name_th : dataObj.name_eng
                }}
              </p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.path") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ dataObj.full_path }}</p></v-flex
            >
          </v-layout>
          <v-layout
            xs12
            lg12
            row
            wrap
            v-if="dataAccountActive.type === 'Business'"
          >
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.permission") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4">
              <v-chip
                small
                class="mt-n1"
                :style="$vuetify.theme.dark ? 'background: #555;' : 'background: #E4ECF9; color: #1B5DD6;'"
                >{{ permission(filedata.file_permission_2) }}</v-chip
              >
            </v-flex>
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">
                {{ $t("keydetail.expiration") }} :
              </p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4"
              ><p>{{ calculatedate(dataObj.expiration_time) }}</p></v-flex
            >
          </v-layout>
          <v-layout xs12 lg12 row wrap>
            <v-flex xs5 lg2 class="pl-4"
              ><p class="font-weight-bold">Hashtag</p></v-flex
            >
            <v-flex xs6 lg10 class="pl-4">
              <v-btn
                x-small
                outlined
                class="elevation-0 text-right"
                color="error"
                :disabled="
                  createprogress ||
                    filedata.editing_status !== 'N' ||
                    filedata.file_permission_2 === '01' ||
                      filedata.file_permission_2 === '02'
                "
                @click="opendialoghashtag()"
                ><v-icon class="ma-1" dark small v-if="filedata.hashtag !== ''"
                  >edit</v-icon
                >{{ $t("edithashtag.edit") }}</v-btn
              ></v-flex
            >
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 class="pl-4">
              <div v-if="dataObj.hashtag == ''">-</div>
              <div v-else>
                <v-chip
                  small
                  class="ma-1"
                  v-for="item in dataObj.hashtag"
                  :key="item"
                  >#{{ item }}</v-chip
                >
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height:420px;" v-if="tab_activity">
        <v-card :style="displayMobile">
                      <v-list dense two-line class="mb-0 pb-0 elevation-0" v-if="history_activity.length !== 0" :style="styleDisplayMobile">
                        <v-list-item v-for="item in history_activity" :key="item.title" style="cursor: pointer" :style="displayMobileForListItem">
                          <v-list-item-content>
                            <v-list-item-title v-if="$t('default') === 'th'" style="line-height:22px;" v-text="item.name_th === null ? 'ไม่ระบุชื่อ' : item.name_th"></v-list-item-title>
                            <v-list-item-title v-else style="line-height:22px;" v-text="item.name_eng === null ? 'Anonymous' : item.name_eng"></v-list-item-title>
                            <v-layout>
                              <v-icon class="mr-1" size="16">mdi-update</v-icon>
                            <v-list-item-subtitle class="ml-1" style="line-height:22px;" v-text="item.process_name"></v-list-item-subtitle>
                            </v-layout>
                            <v-list-item-subtitle v-text="formatdatetime(item.process_dtm)"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('keydetail.no_data_activity')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      </v-card>
        </v-card-text>
        <v-card-text style="height:420px;" v-if="tab_smartbox">
          <div class="d-flex flex-row justify-center pa-3" v-if="isloadingAspect" >
              <v-progress-circular 
                size="32"
                indeterminate
                color="primary"
              /> 
            </div>
          <!-- มีข้อมูล aspect -->
          <div v-if="smart_box === true && !isloadingAspect">
          <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg2 class="pl-4"
                ><p class="font-weight-bold" v-if="$t('default') === 'th'">
                  {{'ชื่อเทมเพลส'}} :
                </p>
                <p class="font-weight-bold" v-else>
                  {{'Template Name'}} :
                </p>
                </v-flex
              >
              <v-flex xs5 lg10 class="pl-0"
              >
              {{data_smartbox.templateName}}
              </v-flex>
            </v-layout>
          <div 
            v-for="(item, i) in data_smartbox.dataAspect"
            :key="i"
            >
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg2 class="pl-4"
                ><p class="font-weight-bold">
                  {{item.aspect_label}} :
                </p></v-flex
              >
              <v-flex xs5 lg10 class="pl-0"
              >
              {{ item.aspect_type === 'DATE' ? calculatedate_smartbox(item.value) : item.value}}
              <v-btn text icon @click="fn_editAspect(item)" >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
              </v-btn>
              </v-flex>
            </v-layout>
            </div>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 class="pl-4"
                ><p class="font-weight-bold">
                  เวอร์ชั่น :
                </p></v-flex
              >
              <v-flex xs5 lg9 class="pl-0"
              >
              {{all_tags_version.find((item) => item.file_id === filedata.file_id ).version }}
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 class="pl-4"
                ><p class="font-weight-bold">
                  จำกัดเวอร์ชั่น :
                </p></v-flex
              >
              <v-flex xs5 class="pl-0" v-if="!isOpenTextFiled_version"
              >
              {{ maxVersion }}
              <v-btn text icon @click="isOpenTextFiled_version = true" >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
              </v-btn>
              </v-flex>
              <v-flex xs2 class="pl-0" v-if="isOpenTextFiled_version"
              >
              <v-text-field hide-details type="number"
               dense v-model="maxVersion" outlined >
               </v-text-field>

              </v-flex>
              <v-flex row xs2 class="pl-0 ml-2" v-if="isOpenTextFiled_version"
              >
              <v-btn text small icon @click="fn_closeMaxVersionEdit" >
                  <v-icon>
                    mdi-close
                  </v-icon>
              </v-btn>
              </v-flex> 
              

            </v-layout>
          </div>
          <!-- ไม่มีข้อมูล aspect -->
          <div v-else-if="smart_box === false && !isloadingAspect">
          <v-layout xs12 lg12 row wrap style="justify-content: center">
              <v-flex xs4 lg2 class="pl-4"
                ><p class="font-weight-bold" v-if="$t('default') === 'th'">
                  {{'ไม่พบข้อมูล'}}
                </p>
                <p class="font-weight-bold" v-else>
                  {{'No information found'}}
                </p>
                </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions class="pa-3" v-if="tab_smartbox && smart_box && isChangeMaxVersion">
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :dark="color.darkTheme"
            @click="fn_restrict_version()"
            >เพิ่มเวอร์ชั่น</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogeditdeletehashtag
      :show="opendialogeditdeletehashtag"
      :filedata="filedata"
      :listcheckhashtag="dataObj"
      @close="opendialogeditdeletehashtag = false"
      @updatehashtag="status_reload = true"
      @reload="reshowhashtag"
    ></dialogeditdeletehashtag>
    <v-dialog
      v-model="edit_aspect"
      scrollable
      persistent
      max-width="480px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ aspect_detail.aspect_label }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-pencil</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-select
          v-if="aspect_detail.aspect_type === 'DROPDOWN'"
              v-model="aspect_detail.aspect_value"
              :items="aspect_detail.aspect_fix_value"
              outlined
              dense
              item-color="color.theme"
              :color="color.theme"
              prepend-icon="mdi-format-list-bulleted-type"
              :disabled="isloadingEdit"
            >
              <template v-slot:item="{ item }">{{ item }}</template>
              <template v-slot:selection="{ item }">{{ item}}</template>
          </v-select>
          <v-text-field
          v-else-if="aspect_detail.aspect_type === 'TEXT'"
            v-model="aspect_detail.aspect_value"
            outlined
            dense
            :disabled="isloadingEdit"
            >
          </v-text-field>
          <v-dialog
            v-else
            persistent
            v-model="aspect_detail.menudate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :color="color.theme"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  :disabled="isloadingEdit"
                  :error-messages="aspect_validator_error"
                  :value="aspect_detail.aspect_date_value">
                </v-text-field>
              </template>
              <v-date-picker
                v-model="aspect_detail.aspect_date_value"
                :locale="$t('default')"
                @input="(aspect_detail.menudate = false)"
                :color="color.theme"
                >
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  color="error"
                  @click="aspect_detail.menudate = false"
                >
                  {{ $t("sharefile.closedatepiker") }}
                </v-btn>
              </v-date-picker>
          </v-dialog>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="(edit_aspect = false)"
            color="red"
            :disabled="isloadingEdit"
            text
            dark
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            color="primary"
            :disabled="isloadingEdit"
            @click="fn_update_aspect(filedata)"
            >แก้ไข</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="edit_aspect"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
    <v-card>
      <v-card-text class="pa-5"> 
        <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ aspect_detail.aspect_label }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="color.theme"
                @click="(edit_aspect = false)"
                >mdi-close</v-icon
              >
            </v-flex>
        </v-layout>
        <v-select
          v-if="aspect_detail.aspect_type === 'DROPDOWN'"
              v-model="aspect_detail.aspect_value"
              :items="aspect_detail.aspect_fix_value"
              outlined
              dense
              item-color="color.theme"
              :color="color.theme"
              prepend-icon="mdi-format-list-bulleted-type"
            >
              <template v-slot:item="{ item }">{{ item }}</template>
              <template v-slot:selection="{ item }">{{ item}}</template>
        </v-select>
        <v-text-field
          v-else-if="aspect_detail.aspect_type === 'TEXT'"
            v-model="aspect_detail.aspect_value"
            outlined
            dense>
        </v-text-field>
        <v-dialog
            v-else
            persistent
            v-model="aspect_detail.menudate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :color="color.theme"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  :disabled="isloadingEdit"
                  :error-messages="aspect_validator_error"
                  :value="aspect_detail.aspect_date_value">
                </v-text-field>
              </template>
              <v-date-picker
                v-model="aspect_detail.aspect_date_value"
                :locale="$t('default')"
                @input="(aspect_detail.menudate = false)"
                :color="color.theme"
                >
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  color="error"
                  @click="aspect_detail.menudate = false"
                >
                  {{ $t("sharefile.closedatepiker") }}
                </v-btn>
              </v-date-picker>
          </v-dialog>
        <div class="text-center">
            <v-btn
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="45%"
              color="red"
              outlined
              @click="(edit_aspect = false)"
              >{{ $t("checkpassword.cancel") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="45%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="fn_update_aspect()"
              >แก้ไข</v-btn
            >
          </div>
      </v-card-text>
    </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email, maxValue } from "vuelidate/lib/validators";
// import gbfGenerate from "../globalFunctions/generateAuthorize";
import dialogeditdeletehashtag from "../optional/dialog-editdeletehashtag";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import format from "date-fns/format";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
    aspect_detail: {
      $each : {
        aspect_value : {
          required
        }
      }
    }

  },
  components: { dialogeditdeletehashtag },
  props: ["show", "parentfolder", "filedata"],
  watch: {
    maxVersion: function (newVal, oldVal) {
      if(oldVal === null){
        this.isChangeMaxVersion = false
        return
      } else {
        if(newVal === null || newVal === ""){
          this.isChangeMaxVersion = false
          return
        }
        if(newVal !== oldVal) {
        this.isChangeMaxVersion = true
        } else {
          this.isChangeMaxVersion = false
        }
      }
     
    }
  },
  data: function() {
    return {
      isloadingAspect: false,
      isloadingEdit: false,
      edit_aspect: false,
      edit_value: "",
      aspect_detail: {
        aspect_id: "",
        aspect_label: "",
        aspect_value: "",
        aspect_fix_value: [],
        aspect_type: "",
        menudate: false,
        aspect_date_value: ""
      },
      // loaddataprogress:false,
      isOpenTextFiled_version: false,
      all_tags_version: [],
      isChangeMaxVersion: false,
      maxVersion: null,
      backup_maxversion: null,
      smart_box: false,
      data_smartbox:{},
      opendialogeditdeletehashtag: false,
      filename: "",
      createprogress: false,
      dataObj: {},
      load_size: false,
      status_reload: false,
      tab_general: true,
      tab_activity: false,
      tab_smartbox: false,
      history_activity:[],
      size: 20,
      page: 1,
      header: [
        {
          text: "keydetail.name_user",
          align: "center",
          value: "name",
          width: "33%",
          sortable: false,
        },       
        {
          text: "keydetail.modified_date",
          align: "center",
          value: "dtm",
          width: "33%",
          sortable: false,
        },
        {
          text: "keydetail.activity",
          align: "center",
          value: "activity",
          width: "33%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.tab_general = true;
          this.tab_activity = false;
          this.tab_smartbox = false;
          let filename = this.filedata.file_name.split(".");

          console.log(filename);

          let i;
          let namewithoutextension = "";
          for (i = 0; i < filename.length; i++) {
            if (i !== filename.length - 1) {
              namewithoutextension += filename[i];
            } else if (filename.length - 1 === 0) {
              namewithoutextension += filename[i];
            }
          }
          if (this.opendialogeditdeletehashtag === false) {
            this.fn_loaddatadetail();
            this.get_activity();
            this.get_smartbox();
          }
          this.filename = namewithoutextension;
          this.$emit("closeDrag");
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closechangename");
        }
      },
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // pageCount: {
    //   get() {
    //     let l = this.listconsent.length;
    //     let s = this.size;
    //     return Math.ceil(l / s);
    //   },
    //   set(newName) {
    //     return newName;
    //   },
    // },
    // paginatedData() {
    //   const start = (this.page - 1) * this.size;
    //   const end = start + this.size;
    //   return this.listconsent.slice(start, start + this.size);
    // },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
     //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    aspect_validator_error() {
      const errors = [];
        if (
          !this.$v.aspect_detail.$each.aspect_value
            .$dirty
        ) {
          return;
        }
    },
  },
  methods: {
    fn_closeMaxVersionEdit () {
      this.isOpenTextFiled_version = false
      this.isChangeMaxVersion = false
      this.maxVersion = [...this.backup_maxversion][0]
    },
    async fn_update_aspect (file_item) {
      try{
        console.log("file_item ", file_item)
        this.isloadingEdit = true
        let payload = {
          user_id: this.dataUsername,
          data_id: file_item.file_id,
          data_type: 'file',
          aspect_data: [
            {
              aspect_id: this.aspect_detail.aspect_id,
              aspect_value: this.aspect_detail.aspect_type !== 'DATE' ? this.aspect_detail.aspect_value : this.changeDateFormat(this.aspect_detail.aspect_date_value)
            }
          ]
        }
        
        console.log("payloaddd file  ", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/update/aspect_data", 
        payload,
        { headers: { Authorization: auth.code } })
        .then((response) => {
          console.log("response dataa ", response);
          if(response.data.status === 'OK'){
            Toast.fire({
              icon: "success",
              title: "Updated aspect",
            });
            this.get_smartbox()
          } else {
            Toast.fire({
              icon: "error",
              title: "Not success",
            });
          }
          this.edit_aspect = false
          this.isloadingEdit = false
        })
        .catch((err) => {
          console.log("Error ", err);
          Toast.fire({
              icon: "error",
              title: this.$t('smartinput.error_some'),
            });
          this.edit_aspect = false
        })

      } catch (err) {

      }
    },
    formatDateString(dateString) {
      // Extract year, month, and day from the input string
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      const day = dateString.slice(6, 8);

      // Return the formatted date string
      return `${year}-${month}-${day}`;
    },
    changeDateFormat(itemDate) {
      if (itemDate) {
        let value = itemDate.split("-").join("");
        return value;
      } else {
        return "";
      }
    },
    fn_editAspect (aspectDetail) {
      this.edit_aspect = true
      this.aspect_detail.aspect_id = aspectDetail.aspect_id
      this.aspect_detail.aspect_label = aspectDetail.aspect_label
      this.aspect_detail.aspect_value = aspectDetail.value
      this.aspect_detail.aspect_type = aspectDetail.aspect_type
      this.aspect_detail.aspect_fix_value = aspectDetail.aspect_type === 'DROPDOWN' ?  aspectDetail.aspect_fix_value : []
      this.aspect_detail.aspect_date_value = aspectDetail.aspect_type === 'DATE' ? this.formatDateString(aspectDetail.value) : null
      // console.log("detail date  ", format(this.aspect_detail.aspect_value, "yyyy-MM-dd"));
    },
    async fn_restrict_version () {
      // ทำการเช็คก่อนว่า limit ที่ส่งมานั้นเป็นค่าว่างมั้ย
      if(this.maxVersion === ""){
        return
      }
      // ทำการเช็คก่อนว่าเลขที่ใส่เข้ามานั้นน้อยกว่าที่มัน limit ยุมั้ย
      if(this.maxVersion < this.all_tags_version.length){
        Toast.fire({
          icon: "error",
          title: "เวอร์ชั่นไฟล์น้อยกว่าเวอร์ชั่นที่มีทั้งหมด",
        });
        return
      } else {
        this.isloadingEdit = true
        this.maxVersion = Math.ceil(this.maxVersion)
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          data_id: this.filedata.file_id,
          data_type: this.filedata.type,
          limit_version: this.maxVersion.toString()
        }
        let auth = await gbfGenerate.generateToken();
      this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/update/limit_version",
         payload,{ headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if(response.data.status === 'OK') {
            Toast.fire({
              icon: "success",
              title: "Restrict max version",
            });
            this.isOpenTextFiled_version = false
            this.isChangeMaxVersion = false
            this.isloadingEdit = false
          } else {
            Toast.fire({
              icon: "error",
              title: "Not success",
            });
            this.isOpenTextFiled_version = false
            this.isChangeMaxVersion = false
            this.maxVersion = [...this.backup_maxversion][0]
            this.isloadingEdit = false
          }
          console.log('responsee ', response)
        })
        .catch((err) => {
          Toast.fire({
              icon: "error",
              title: "Not success",
            });
            this.isOpenTextFiled_version = false
            this.isChangeMaxVersion = false
            this.maxVersion = [...this.backup_maxversion][0]
            this.isloadingEdit = false
        })
        console.log("ผ่านการตรวจสอบบบ")
      }
    },
    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
    async get_activity(){
      console.log("get_activity");
      let payload = {
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type == "folder" ? "folder" : "file"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/get/data/log", payload,{ headers: { Authorization: auth.code } })
        .then((response) => {
          console.log("response",response);
          if (response.data.status == "OK") {
              this.history_activity = response.data.data;
              // console.log("history_activity",this.history_activity);
          } 
          else {
            Toast.fire({
              icon: "error",
              title: this.$t("ไม่สามารถเรียกข้อมูลได้"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });

    },
    async get_smartbox(){
      console.log("get_smartbox");
      if(this.filedata.file_type === "folder"){
        this.smart_box = false
        return 
      }
      this.isloadingAspect = true
      let payload = {
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type == "folder" ? "folder" : "file"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/smartbox/v1/get/info_files", payload,{ headers: { Authorization: auth.code } })
        .then(async (response) => {
          console.log("response",response);
          if (response.data.status == "OK") {
            console.log("dataAspect", response.data.data.dataAspect);
            if(response.data.data.templateName == ''){
              this.smart_box = false;
            }else{
              this.smart_box = true;
            this.data_smartbox = response.data.data
            this.data_smartbox.version = response.data.data.version
            console.log(this.data_smartbox);
            this.maxVersion = [null, 'null'].includes(this.data_smartbox.version.limit_version) ? 1 : this.data_smartbox.version.limit_version
            this.backup_maxversion = [null, 'null'].includes(this.data_smartbox.version.limit_version) ? ["1"] : [this.data_smartbox.version.limit_version]
            await this.fn_view_version(this.filedata.file_id)
            }
            this.isloadingAspect = false
          } 
          else {
            Toast.fire({
              icon: "error",
              title: this.$t("ไม่สามารถเรียกข้อมูลได้"),
            });
          }
          this.isloadingAspect = false
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.isloadingAspect = false
        });
    },
    async fn_view_version(file_id) {
      let auth = await gbfGenerate.generateToken();
      let payload = {
        file_id: file_id,
        folder_id: this.parentfolder,
        tag_id: this.filedata.tag_id || "",
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/all_file_tag_version",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("tag-version response", response);
          if (response.data.status === "OK") {
            this.all_tags_version = response.data.result
            
          } else {
            
          }
        })
        .catch((e) => {
          
        });
    },
    reshowhashtag() {
      this.fn_loaddatadetail();
      this.get_activity();
      this.get_smartbox();
    },
    opendialoghashtag() {
      this.opendialogeditdeletehashtag = true;
    },
    // แปลงค่าวัน
    fn_dateFormat() {
      const datetime = Date.now();
      var d = new Date(datetime);
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var year = d.getFullYear().toString();
      var month = months[d.getMonth()];
      var day = d.getDate();
      var hour = d.getHours();
      var min = d.getMinutes();

      console.log(
        year + "-" + month + "-" + day + "T" + hour + ":" + min + "_" + "Bearer"
      );
      let newdatetime =
        year +
        "-" +
        month +
        "-" +
        day +
        "T" +
        hour +
        ":" +
        min +
        "_" +
        "Bearer";

      return newdatetime;
    },
    // Authorization header fix อยู่
    fn_getCodeAuthen() {
      let decode = window.atob(
        process.env.VUE_APP_BEARER_TOKEN
      );
      let str =
        this.fn_dateFormat() +
        " " +
        process.env.VUE_APP_BEARER_TOKEN;
      console.log(str, decode);
      let code = window.btoa(str);
      return code;
    },
    // โหลดข้อมูลไฟล์และโฟลเดอร์
    async fn_loaddatadetail() {
      this.createprogress = true;
      // console.log("filedata:", this.filedata);
      // โหลดข้อมูลโฟลเดอร์
      if (this.filedata.file_type === "folder") {
        let payload = {
          folder_id: this.filedata.file_id,
          account_id: this.dataAccountId,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            this.$router.app["_route"]["name"] === "trash" ?  //ใช้งาน get data folder file คนละเส้นกับในถังขยะ
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +  "/api/info_data_folder_trash"
            :
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +  "/api/info_data_folder",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            this.load_size = true;
            if (response.data.status === "OK") {
              this.dataObj = response.data.result;
              this.createprogress = false;
              // วนลูปบวกขนาดของโฟลเดอร์
              for (let i = 0; i < this.dataObj.url_size_folder.length; i++) {
                this.axios
                  .get(this.dataObj.url_size_folder[i].url, {
                    headers: { Authorization: auth.code },
                  })
                  .then((response) => {
                    if (response.data.status === "OK") {
                      // ค่าเป็น string ต้องแปลงค่าก่อนบวก
                      this.dataObj.size =
                        parseInt(this.dataObj.size) +
                        parseInt(response.data.result.size_folder);
                      // เช็คเพื่อปิดตัวโหลดขนาดไฟล์
                      if (i + 1 === this.dataObj.url_size_folder.length) {
                        this.load_size = false;
                      }
                    } else {
                      this.load_size = false;
                      Toast.fire({
                        icon: "error",
                        title: this.$t("keydetail.cannotopendatafile"),
                      });
                    }
                  })
                  .catch((e) => {
                    this.load_size = false;
                    Toast.fire({
                      icon: "error",
                      title: this.$t("keydetail.cannotopendatafile"),
                    });
                    // console.log("error data_size_folder", e);
                  });
              }
            } else {
              this.createprogress = false;
              this.load_size = false;
              Toast.fire({
                icon: "error",
                title: this.$t("keydetail.cannotopendatafile"),
              });
            }
          })
          .catch((e) => {
            this.createprogress = false;
            // console.log("error info_data_folder", e);
            Toast.fire({
              icon: "error",
              title: this.$t("keydetail.cannotopendatafile"),
            });
          });
        // โหลดข้อมูลไฟล์
      } else {
        let payload = {
          data_id: this.filedata.file_id,
          data_type: this.filedata.file_type == "folder" ? "folder" : "file",
          account_id: this.dataAccountId,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            this.$router.app["_route"]["name"] === "trash" ?  //ใช้งาน get data folder file คนละเส้นกับในถังขยะ
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +  "/api/info_data_trash"
            :
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +  "/api/info_data",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("response dialog keydetail", response);
            if (response.data.status === "OK") {
              this.dataObj = response.data.result;
              console.log("this.dataObj", this.dataObj);
              this.createprogress = false;
              // setTimeout(() => {
              //   this.createprogress = false;
              // }, 2500);
            } else {
              this.createprogress = false;
              Toast.fire({
                icon: "error",
                title: this.$t("keydetail.cannotopendatafile"),
              });
            }
          })
          .catch((e) => {
            this.createprogress = false;
            // console.log("error fn_loaddatadetail (file)", e);
            Toast.fire({
              icon: "error",
              title: this.$t("keydetail.cannotopendatafile"),
            });
          });
      }
    },
    calculatesize(parameter) {
      if (parameter === undefined) {
        return " ";
      }
      // console.log("parameter",parameter);
      let size;
      if (parameter >= 1099511992567 && parameter <= 1125899906842624) {
        size = (parameter / 1099511992567).toFixed(2) + " TB";
      } else if (parameter >= 1073741824 && parameter < 1099511992567) {
        size = (parameter / 1073741824).toFixed(2) + " GB";
      } else if (parameter >= 1048576 && parameter < 1073741824) {
        size = (parameter / 1048576).toFixed(2) + " MB";
      } else if (parameter >= 1024 && parameter < 1048576) {
        size = (parameter / 1024).toFixed(2) + " KB";
      } else if (parameter === "-") {
        size = parameter;
      } else {
        size = parameter + " B";
      }

      return size;
    },
    calculatedate(parameter) {
      let date = [];
      let month, finalDate;
      if (parameter === "00000000000000") {
        return "-";
      } else if (parameter) {
        date.push(parameter[0] + parameter[1] + parameter[2] + parameter[3]);
        date.push(parameter[4] + parameter[5]);
        date.push(parameter[6] + parameter[7]);
        date.push(parameter[8] + parameter[9]);
        date.push(parameter[10] + parameter[11]);
        date.push(parameter[12] + parameter[13]);
        var months = [
          this.$t("keydetail.month.jan"),
          this.$t("keydetail.month.feb"),
          this.$t("keydetail.month.mar"),
          this.$t("keydetail.month.apr"),
          this.$t("keydetail.month.may"),
          this.$t("keydetail.month.jun"),
          this.$t("keydetail.month.jul"),
          this.$t("keydetail.month.aug"),
          this.$t("keydetail.month.sep"),
          this.$t("keydetail.month.oct"),
          this.$t("keydetail.month.nov"),
          this.$t("keydetail.month.dec"),
        ];
        month = months[date[1] - 1];
        let datebuff =
          date[3] + ":" + date[4] + " " + date[2] + " " + month + " " + date[0];
        finalDate = datebuff;
      } else {
        finalDate = "-";
      }

      return finalDate;
    },
    calculatedate_smartbox(parameter) {
      let date = [];
      let month, finalDate;
      if (parameter === "00000000") {
        return "-";
      } else if (parameter) {
        date.push(parameter[0] + parameter[1] + parameter[2] + parameter[3]);
        date.push(parameter[4] + parameter[5]);
        date.push(parameter[6] + parameter[7]);
        date.push(parameter[8] + parameter[9]);
        date.push(parameter[10] + parameter[11]);
        date.push(parameter[12] + parameter[13]);
        var months = [
          this.$t("keydetail.month.jan"),
          this.$t("keydetail.month.feb"),
          this.$t("keydetail.month.mar"),
          this.$t("keydetail.month.apr"),
          this.$t("keydetail.month.may"),
          this.$t("keydetail.month.jun"),
          this.$t("keydetail.month.jul"),
          this.$t("keydetail.month.aug"),
          this.$t("keydetail.month.sep"),
          this.$t("keydetail.month.oct"),
          this.$t("keydetail.month.nov"),
          this.$t("keydetail.month.dec"),
        ];
        month = months[date[1] - 1];
        let datebuff = " " + date[2] + " " + month + " " + date[0];
        finalDate = datebuff;
      } else {
        finalDate = "-";
      }

      return finalDate;
    },
    permission(parameter) {
      console.log("parameter", parameter);
      // return parameter
      if (parameter === "01") {
        return this.$t("keydetail.viewonly");
      } else if (parameter === "02" || parameter === "03") {
        return this.$t("keydetail.downloadonly");
      } else if (
        parameter === "04" ||
        parameter === "05" ||
        parameter === "06"
      ) {
        return this.$t("keydetail.edit_download");
      }
    },
  },
};
</script>
<style></style>
